import _core from "./core";
import _sha from "./sha256";
import _hmac from "./hmac";
var exports = {};
;

(function (root, factory, undef) {
  // CommonJS
  exports = exports = factory(_core, _sha, _hmac);
})(exports, function (CryptoJS) {
  return CryptoJS.HmacSHA256;
});

export default exports;